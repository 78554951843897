import React, {Component} from 'react';
import './personalProjects.css';

class PersonalProjects extends Component {
  render() {
    return (
      <div className="personalProjects">
        <p>Personal projects will soon be here!</p>
      </div>
    );
  };
};

export default PersonalProjects;
