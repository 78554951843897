import React, {Component} from 'react';
import './footer.css';

// This is the footer, later down the road I plan on adding a place to send your email to start collecting emails
class Footer extends Component {
    render() {
        return (
            <div className="footer">
                
            </div>
        );
    };
};

export default Footer;
